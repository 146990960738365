<template>
  <b-container fluid="">
    <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="font-weight-bold">Drive</h4>
      </div>
      <div class="create-workform">
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <div class="modal-product-search d-flex">
            <div class="form-group mr-3">
              <input type="date" class="form-control" id="exampleInputdate"
                     v-model="filter_min_date">
            </div>
            <div class="form-group mr-3">
              <input type="date" class="form-control" id="exampleInputdate2"
                     v-model="filter_max_date">
            </div>
            <button :disabled="loading_charts" @click="getTimeLineCharts" type="button"
                    class="btn btn-primary position-relative d-flex align-items-center justify-content-between svg-icon">
              <i class="ri-bill-fill"></i>Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <h4 class="mt-2 mb-4"><b>Uso del dominio <span class="text-blue" v-text="auth_user.domain_user"></span></b> <small>del <b>{{ format_min_date }}</b> al  <b>{{ format_max_date }}</b></small></h4>
    <div v-if="loading_charts">
      <b-spinner variant="primary" label="Spinning"></b-spinner> Cargando, espere...
    </div>
    <div v-else>
      <b-row>
        <b-col lg="6" v-for="(item,index) in charts" :key="index">
          <card>
            <template v-slot:body>
              <div v-if="loading_charts">
                <b-spinner variant="primary" label="Spinning"></b-spinner> Cargando, espere...
              </div>
              <ApexChart v-else :element="`chart-${index}`" :chartOption="item.bodyData"/>
            </template>
          </card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import ApexChart from '../../../components/charts/ApexChart'
import moment from "moment";
export default {
  props: ['auth_user'],
  name: 'Classroom',
  components: {
    ApexChart
  },

  data() {
    return {
      loading_charts: true,
      filter_min_date: '2021-10-01',
      filter_max_date: '2021-10-31',
      format_min_date: '',
      format_max_date: '',
      charts: []
    }
  },

  mounted() {
    this.getTimeLineCharts()
  },
  methods: {
    formatDateByArray(dates = []) {
      let dates_converted = []
      for ( let d = 0; d < dates.length; d++ )
      {
        dates_converted.push(moment(dates[d], "YYYY-MM-DD").format("DD/MMM/YYYY"))
      }
      return dates_converted
    },
    formatRangeDates() {
      this.format_min_date = moment(this.filter_min_date, "YYYY-MM-DD").format("DD/MMM/YYYY")
      this.format_max_date = moment(this.filter_max_date, "YYYY-MM-DD").format("DD/MMM/YYYY")
    },
    getTimeLineCharts() {
      this.formatRangeDates()
      this.loading_charts = true
      this.$api.get(`/api/customer-usage/get-timeline-charts-drive?start_date=${this.filter_min_date}&end_date=${this.filter_max_date}`)
          .then(res => {
            this.drawLineCharts(res.data.data)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.loading_charts = false
          })
    },
    drawLineCharts(charts_data = []) {
      this.charts[0] = {
        title: '',
        type: 'line',
        bodyData: {
          title: {
            text: charts_data.sum_num_drive_items_other_created.description,
            align: 'left'
          },
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ["#4788ff"],
          series: [{
            name: charts_data.sum_num_drive_items_other_created.label,
            data: charts_data.sum_num_drive_items_other_created.data
          }],
          xaxis: {
            categories: this.formatDateByArray(charts_data.sum_num_drive_items_other_created.dates)
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ],
          fill: {
            opacity: 1

          },
        }
      }
      this.charts[1] = {
        title: '',
        type: 'line',
        bodyData: {
          title: {
            text: charts_data.sum_num_drive_items_other_edited.description,
            align: 'left'
          },
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ["#4788ff"],
          series: [{
            name: charts_data.sum_num_drive_items_other_edited.label,
            data: charts_data.sum_num_drive_items_other_edited.data
          }],
          xaxis: {
            categories: this.formatDateByArray(charts_data.sum_num_drive_items_other_edited.dates)
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ],
          fill: {
            opacity: 1

          },
        }
      }
      this.charts[2] = {
        title: '',
        type: 'line',
        bodyData: {
          title: {
            text: charts_data.sum_num_drive_items_other_trashed.description,
            align: 'left'
          },
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ["#4788ff"],
          series: [{
            name: charts_data.sum_num_drive_items_other_trashed.label,
            data: charts_data.sum_num_drive_items_other_trashed.data
          }],
          xaxis: {
            categories: this.formatDateByArray(charts_data.sum_num_drive_items_other_trashed.dates)
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ],
          fill: {
            opacity: 1

          },
        }
      }
      this.charts[3] = {
        title: '',
        type: 'line',
        bodyData: {
          title: {
            text: charts_data.sum_num_drive_items_other_viewed.description,
            align: 'left'
          },
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ["#4788ff"],
          series: [{
            name: charts_data.sum_num_drive_items_other_viewed.label,
            data: charts_data.sum_num_drive_items_other_viewed.data
          }],
          xaxis: {
            categories: this.formatDateByArray(charts_data.sum_num_drive_items_other_viewed.dates)
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ],
          fill: {
            opacity: 1

          },
        }
      }
    },
    generateData(baseval, count, yrange) {
      var i = 0
      var series = []
      while (i < count) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
        var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15

        series.push([baseval, y, z])
        baseval += 86400000
        i++
      }
      return series
    }
  },
}
</script>
